
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import IconPP from "@/components/IconPP.vue";

export default {
  components: {
    Footer,
    Navbar,
    IconPP,
  },
};
